//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Desktop'
};