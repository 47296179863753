//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import navigation from '@/router/navigation';
export default {
  name: 'PageSidebar',
  data: function data() {
    return {
      navigation: navigation
    };
  }
};