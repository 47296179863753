export default [
  // {
  //   name: '',
  //   color: '#A4A4A4',
  //   routes: [
  //     {
  //       title: '#MenuTitle Dashboard',
  //       icon: 'HomeIcon',
  //       route: 'dashboard',
  //     },
  //   ],
  // },
  {
    name: '#MenuHeader Lab',
    color: '#3888FF',
    routes: [
      {
        title: '#MenuTitle Series',
        icon: 'BookIcon',
        route: 'lab-series',
      },
    ],
  },
]